<template>
  <div
    class="bitts-select"
    :class="{
      error,
      'read-only': readOnly,
    }"
  >
    <BittsFormLabel
      v-if="formLabel"
      class="bitts-input-select__label"
      :label="formLabel"
      :disabled="disabled"
    />
    <ASelect
      v-bind="selectProps()"
      v-model:value="value"
      :size="size"
      :popup-class-name="dropdownClass"
      :allow-clear="allowClear"
      :autofocus="autoFocus"
      :default-open="defaultOpen"
      :list-height="listHeight"
      :loading="loading"
      :show-search="searchable"
      :disabled="disabled"
      :get-popup-container="getPopupContainer"
      :filter-option="(val, opt) => filterOptions(val, opt, options)"
      :dropdown-match-select-width="dropdownMatchSelectWidth"
      @[addSearchHandler]="handleSearch"
      @change="handleChange"
    >
      <template #placeholder>
        <slot name="placeholder">
          <div class="bitts-select__placeholder">
            <div v-if="optionType" class="flex items-center">
              <FontAwesomeIcon
                v-if="optionType && prefixIcon"
                :icon="prefixIcon"
                :style="{ width: '16px', color: 'currentColor' }"
                class="mr-8"
              />
              {{ placeholder }}
            </div>
          </div>
        </slot>
      </template>
      <template #clearIcon>
        <FontAwesomeIcon
          :icon="['far', 'circle-xmark']"
          :style="{ width: '12px', color: 'currentColor' }"
        />
      </template>
      <template #suffixIcon>
        <FontAwesomeIcon
          :icon="['fas', 'chevron-down']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>
      <template #removeIcon>
        <FontAwesomeIcon
          :icon="['fas', 'close']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>
      <template #menuItemSelectedIcon>
        <FontAwesomeIcon :icon="['fas', 'check']" :style="{ width: '12px' }" />
      </template>
      <template #notFoundContent>
        <p class="text-neutral-text text-center">
          {{ noneRemainingMessage }}
        </p>
      </template>
      <template v-if="dropdownButton" #dropdownRender="{ menuNode: menu }">
        <VNodes :vnodes="menu" />
        <slot name="dropdownButton" />
      </template>
      <template #default>
        <template v-if="hasOptGroups">
          <SelectOptGroup
            v-for="(optGroup, index) in options"
            :key="optGroup.heading + index"
          >
            <template #label>
              <div
                ref="bitts-select-opt-group"
                class="text-neutral-text text-sm leading-4 flex items-center"
                :class="{
                  'px-8 py-4': optGroup.heading,
                  'py-2': !optGroup.heading && index !== 0,
                }"
              >
                <BittsSvg
                  v-if="headingType === 'svg'"
                  :svg="optGroup.svg"
                  class="w-16 mr-8"
                />
                {{ optGroup?.heading }}
              </div>
            </template>
            <SelectOption
              v-for="(option, idx) in optGroup.items"
              ref="bitts-select-opt"
              :key="`${optGroup.heading}--${index}--${option.value}--${idx}`"
              :value="option.value"
            >
              <component
                v-bind="tooltipProps"
                :is="divOrTooltip(option)"
                ref="bitts-select-option"
                class="flex items-center"
                :class="{
                  'cursor-not-allowed opacity-50':
                    useDisabledOptions &&
                    option.disabled &&
                    option.value !== modelValue,
                }"
                @cta-clicked="$emit('tooltip-cta-clicked')"
              >
                <template #default>
                  <FontAwesomeIcon
                    v-if="option.icon"
                    class="c-bitts-select__option-icon mr-8"
                    :icon="option.icon"
                    :style="{ width: '16px', color: 'currentColor' }"
                    :class="option.iconColor ? option.iconColor : null"
                  />
                  <BittsAvatar
                    v-else-if="
                      optionType === 'company' && option.value !== 'all'
                    "
                    size="x-small"
                    :is-own="false"
                    :show-initials="true"
                    :org="option"
                    class="mr-8"
                  />
                  <BittsSvg
                    v-else-if="optionType === 'svg' && option.svg"
                    :svg="option.svg"
                    class="w-16 h-16 mr-8"
                  />
                  <span class="w-full truncate">
                    {{ option.label }}
                  </span>
                  <slot name="suffix" :option="option" />
                </template>
                <template #title>
                  {{ disabledOptionContent?.text }}
                </template>
              </component>
            </SelectOption>
          </SelectOptGroup>
        </template>
        <template v-else>
          <SelectOption
            v-for="(option, idx) in options"
            ref="bitts-select-opt"
            :key="`${option.value}--${idx}`"
            :value="option.value"
            :disabled="useDisabledOptions && option.disabled"
          >
            <component
              v-bind="tooltipProps"
              :is="divOrTooltip(option)"
              ref="bitts-select-option"
              class="flex items-center"
              :class="{
                'cursor-not-allowed opacity-50':
                  useDisabledOptions && option.disabled,
              }"
              @cta-clicked="$emit('tooltip-cta-clicked')"
            >
              <slot name="option" :option="option">
                <div class="flex items-center w-full">
                  <FontAwesomeIcon
                    v-if="option.icon"
                    :icon="option.icon"
                    :style="{ width: '16px', color: 'currentColor' }"
                    :class="option.iconColor ? option.iconColor : null"
                    class="c-bitts-select__option-icon mr-8"
                  />
                  <BittsAvatar
                    v-else-if="optionType === 'company' && option.domain"
                    size="x-small"
                    :is-own="false"
                    :show-initials="true"
                    :org="option"
                    class="mr-8"
                  />
                  <BittsAvatar
                    v-else-if="optionType === 'entity'"
                    size="x-small"
                    :is-own="false"
                    :show-initials="true"
                    :user="option"
                    class="mr-8"
                  />
                  <BittsSvg
                    v-else-if="optionType === 'svg' && option.svg"
                    :svg="option.svg"
                    class="w-16 h-16 mr-8"
                  />
                  <span class="bitts-select__option-label">{{
                    option.label
                  }}</span>
                  <slot name="suffix" :option="option" />
                </div>
              </slot>
              <template #title>
                {{ disabledOptionContent?.text }}
              </template>
            </component>
          </SelectOption>
        </template>
      </template>
    </ASelect>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Select as ASelect } from 'ant-design-vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTag from '../BittsTag/BittsTag.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

const searchForValue = (val, option, options) => {
  const found = options.find((opt) => opt.value === option.value);
  if (found)
    return found.label.toLowerCase().includes(val.trim().toLowerCase());
};

export default {
  name: 'BittsSelect',
  components: {
    ASelect,
    SelectOption: ASelect.Option,
    SelectOptGroup: ASelect.OptGroup,
    BittsAvatar,
    BittsFormLabel,
    BittsTag,
    BittsTooltip,
    FontAwesomeIcon,
    BittsSvg,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  props: {
    allowClear: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownClassName: {
      type: String,
      default: '',
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: true,
    },
    dynamicOptions: {
      type: Boolean,
      default: false,
    },
    hasOptGroups: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Select Field',
    },
    listHeight: {
      type: Number,
      default: 256,
    },
    optionType: {
      type: String,
      default: 'icon',
      validator: (type) =>
        ['default', 'entity', 'icon', 'company', 'svg'].includes(type),
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    formLabel: {
      type: [Object, String],
      default: null,
    },
    defaultToFirstOption: {
      type: Boolean,
      default: false,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    headingType: {
      type: String,
      default: 'default',
      validator: (type) => ['default', 'svg'].includes(type),
    },
    modelValue: {
      type: [String, Array, Number],
      default: undefined,
    },
    mountToBody: {
      type: Boolean,
      default: true,
    },
    prefixIcon: {
      type: [String, Object, Array],
      default: null,
    },
    noneRemainingMessage: {
      type: String,
      default: 'No results found',
    },
    useDisabledOptions: {
      type: Boolean,
      default: false,
    },
    disabledOptionContent: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    dropdownButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
      validator: () => ['small', 'medium', 'large'],
    },
  },
  emits: [
    'search-changed',
    'update:modelValue',
    'clear',
    'change',
    'tooltip-cta-clicked',
  ],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value, this.modelValue);
      },
    },
    tooltipProps() {
      const props = {
        trigger: 'hover',
        align: { offset: [0, 36] },
        'hide-arrow': true,
        'mount-to-body': true,
      };
      if (this.disabledOptionContent?.includeCta) {
        props.includeCta = true;
        props.buttonText = this.disabledOptionContent.buttonText;
        props.learnMoreLink = this.disabledOptionContent.learnMoreLink;
      }
      return props;
    },
    dropdownClass() {
      return `${this.dropdownClassName} bitts-select__dropdown`;
    },
    addSearchHandler() {
      return this.searchable ? 'search' : null;
    },
  },
  created() {
    this.validateOptionsSchema();
  },
  methods: {
    handleChange(val) {
      if (!val) this.$emit('clear');
      this.$emit('change', val);
    },
    handleSearch(query) {
      this.$emit('search-changed', query);
    },
    filterOptions(val, option, options) {
      // if options are dynamic, all options are valid
      if (this.dynamicOptions) return true;

      if (!this.hasOptGroups) {
        return searchForValue(val, option, options);
      }
      for (const optgroup of options) {
        const found = searchForValue(val, option, optgroup.items);
        if (found) return true;
      }
      return false;
    },
    getPopupContainer(trigger) {
      return this.mountToBody ? document.body : trigger.parentElement;
    },
    validateOptionsSchema() {
      if (!this.options.length) return;
      if (this.hasOptGroups) {
        const [firstOption] = this.options;
        if (!Object.prototype.hasOwnProperty.call(firstOption, 'heading')) {
          console.warn('optGroups options should have a heading property');
        }
        if (this.optionType === 'company') {
          if (!Object.prototype.hasOwnProperty.call(firstOption, 'items')) {
            console.warn('optGroups options should have an items property');
          }
          if (
            this.prefixIcon &&
            !Object.prototype.hasOwnProperty.call(this.prefixIcon, 'domain')
          ) {
            console.warn(
              'prefix icon is missing domain which is needed for avatar',
            );
          }
        }
      }
    },
    selectProps() {
      const newProps = {};
      if (this.modelValue) newProps.defaultValue = this.modelValue;
      if (this.defaultToFirstOption)
        newProps.defaultValue = this.options[0].value;
      return newProps;
    },
    divOrTooltip(option) {
      return this.useDisabledOptions &&
        option?.disabled &&
        option.value !== this.modelValue &&
        this.disabledOptionContent
        ? BittsTooltip
        : 'div';
    },
  },
};
</script>

<style lang="pcss">
.bitts-select {
  .ant-select {
    @apply text-base leading-6 w-full;
    font-family: inherit;
  }

  .ant-select .ant-select-selector {
    @apply text-base;
    font-family: inherit;
  }

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    @apply border-neutral-border;
  }

  .ant-select-item {
    @apply mx-12 flex items-center;
  }

  .ant-select-arrow svg {
    @apply transition-transform;
  }
  .ant-select-open .ant-select-arrow svg {
    @apply rotate-180;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-lg border-neutral-border shadow-component;
  }
  .bitts-select__placeholder {
    @apply text-neutral-text-placeholder;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply bg-neutral-background-disabled;
  }

  &.error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 1px theme(colors.danger.border-focus);
    @apply border-danger-border-focus;
  }
  .ant-select-single .ant-select-clear {
    @apply mr-16 mt-[-6px];
  }

  &.read-only {
    @apply pointer-events-none;
    .ant-select-arrow {
      @apply hidden;
    }
  }
}

.bitts-select__option-label {
  @apply w-full truncate;
}

.ant-select-dropdown .ant-select-item {
  @apply text-neutral-text-strong py-5 px-8 rounded;
}

.ant-select-item-option:last-child {
  @apply mb-0;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  @apply bg-neutral-background-weak;
}
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector,
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  box-shadow: 0 0 0 1px theme(colors.neutral.border-focus);
  @apply border-neutral-border-focus;
}

.ant-select-item.ant-select-item-group {
  @apply p-0 h-auto min-h-[auto];
}

.ant-select-item.ant-select-item-group:not(:first-child) {
  @apply border-t border-neutral-border mt-2;
}
.ant-select-dropdown-menu-item-group:not(:last-child) {
  @apply border-b border-b-neutral-border pb-4 mb-4;
}
.ant-select-dropdown {
  font-family: inherit;
  @apply rounded-8 shadow-component border border-neutral-border p-0 py-4;
}
.ant-select-dropdown-menu {
  @apply p-4;
}
.ant-select-item .ant-select-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child)
  .ant-select-item-option-grouped
  .ant-select-dropdown-menu-item:last-child {
  @apply rounded-4;
}
.bitts-select__dropdown {
  .ant-select-item-option-active {
    @apply bg-neutral-background-weak;
  }
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    @apply bg-secondary-background-weak font-normal;
  }

  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    @apply text-secondary-accent;
  }

  .ant-empty {
    @apply flex justify-center items-center text-neutral-text-weak;
  }
}
</style>
